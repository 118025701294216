import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Overhead Band Pull Aparts & Snatch Grip Press from back, 4×8`}</p>
    <p>{`Overhead Squats 4×4\\@85% 1RM`}</p>
    <p>{`then,`}</p>
    <p><em parentName="p">{`“Barbell Cycling”`}</em></p>
    <p>{`4:00 EMOM (every minute on the minute):`}</p>
    <p>{`10-Power Snatch`}</p>
    <p>{`rest until minute 6:00, then:`}</p>
    <p>{`4:00 EMOM:`}</p>
    <p>{`8-Hang Power Snatch`}</p>
    <p>{`rest until minute 12:00, then:`}</p>
    <p>{`4:00 EMOM of:`}</p>
    <p>{`6-Full Snatch`}</p>
    <p><em parentName="p">{`*`}{`using approximately 55% 1RM.  Can adjust weight between movements if
needed.`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`If you haven’t already please find the Crossfittheville Facebook
page and the private group created for Ville members.  We will be
sending at home workouts through this group.  If you cannot get on
Facebook please let us know and we will email them to you.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      